import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBookingRecord } from '../../redux/getBookingRecordSlice';
import { clearUpdateBookingStatusData, updateBookingStatus } from '../../redux/updateBookingStatusSlice';
import PaginationComponent from '../Pagination/PaginationComponent';
import { ClipLoader } from "react-spinners";
import Modal from 'react-modal';
import { writeFile, utils } from "xlsx";
import { clearGetBookingRecordsReportData, getBookingRecordsReport } from '../../redux/getBookingRecordsReportSlice';
import { clearGetBillReportData, getBillReport } from '../../redux/getBillReportSlice';

const modal_setting = {
    content: {
        top: "20%",
        left: "50%",
        right: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, 0)",
        maxWidth: "100%",
        width: "420px",
        borderRadius: "8px",
    },
};

const Booking = () => {

    const navigation = useNavigate();
    const dispatch = useDispatch();

    const [loaderVisible, setLoaderVisible] = useState(false)
    const [skip, setSkip] = useState(0);
    const [count, setCount] = useState(1)
    const [bookings, setBookings] = useState([])
    const [status, setStatus] = useState(0)
    const [bookingId, setBookingId] = useState("")
    const [search, setSearch] = useState("");
    const [isOpenCalender, setOpenCalendar] = useState(false);
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [bookingRecord, setBookingRecord] = useState(null)
    const [location, setLocation] = useState("")

    const getBookingListSuccess = useSelector((state) => state.getBookingRecordReducer.data);
    const updateBookingStatusResponse = useSelector((state) => state.updateBookingStatusReducer.data);
    const getBookingRecordsReportResponse = useSelector((state) => state.getBookingRecordsReportReducer.data);
    const getBillReportResponse = useSelector((state) => state.getBillReportReducer.data);


    // const onStatusChange = (item) => {
    //     console.log("onStatusChange.status---", item.status)
    //     let status = item.status == 0 ? 1 : 2;
    //     setStatus(status);
    //     let payload = {
    //         status: status,
    //         bookingId: item._id
    //     }
    //     setBookingId(item._id);
    //     dispatch(updateBookingStatus(payload))
    // }

    useEffect(() => {
        console.log("updateBookingStatusResponse updateBookingStatusResponse ===>", updateBookingStatusResponse)
        if (updateBookingStatusResponse != null && updateBookingStatusResponse.status === 1) {
            // alert("Update Success!")
            setBookings((prevBookings) =>
                prevBookings.map((booking) =>
                    booking._id === bookingId ? { ...booking, status: status } : booking
                )
            );
        }
        return () => { dispatch(clearUpdateBookingStatusData()) };
    }, [updateBookingStatusResponse])

    useEffect(() => {
        if (getBookingListSuccess != null && getBookingListSuccess.status == 1) {
            setLoaderVisible(false)
            setBookings(getBookingListSuccess.data)
            const count = Math.floor(getBookingListSuccess.count / 20)
            setCount(count + 1)
        } else {
            setLoaderVisible(false)
        }
    }, [getBookingListSuccess])

    useEffect(() => {
        console.log('getBillReportResponse  ===>', getBillReportResponse)
        if (getBillReportResponse != null && getBillReportResponse.status == 1) {
            setLocation(getBillReportResponse.data.Location)
            window.open(getBillReportResponse.data.Location, '_blank');
            dispatch(clearGetBillReportData());
        } else {
            setLoaderVisible(false)
        }
    }, [getBillReportResponse])


    const onSubmitPrintClick = (item) => {
        const paylaod = {
            bookingId: item._id,
        }
        dispatch(getBillReport(paylaod))
    }

    useEffect(() => {
        setLoaderVisible(true)
        const paylaod = {
            skip: skip,
            search: search,
        }
        dispatch(getBookingRecord(paylaod))
    }, [skip, search])

    const filteredBookings = bookings.filter(item => {
        const fullName = `${item.userId?.firstName || ""} ${item.userId?.lastName || ""}`;
        return (
            fullName.toLowerCase().includes(search.toLowerCase()) ||
            item.bookingId.toString().includes(search) ||
            item.amount.toString().includes(search)
        );
    });

    const ExportToExcel = (data) => {
        console.log("Export Data ===>", data)
        if (data != null && data.length > 0) {
            const worksheet = utils.json_to_sheet(data);
            const workbook = utils.book_new();
            utils.book_append_sheet(workbook, worksheet, "Sheet1");
            writeFile(workbook, "data.xlsx");
        }
        setBookingRecord(null)
    };

    useEffect(() => {
        console.log("getBookingRecordsReportResponse data ===>", getBookingRecordsReportResponse)
        if (getBookingRecordsReportResponse != null && getBookingRecordsReportResponse.status === 1) {
            setOpenCalendar(false)
            setStartDate("")
            setEndDate("")
            setBookingRecord(getBookingRecordsReportResponse.data)
        }
        return () => { dispatch(clearGetBookingRecordsReportData()) };
    }, [getBookingRecordsReportResponse])


    const onSubmitDateClick = () => {
        const paylaod = {
            startDate: startDate,
            endDate: endDate,
        }
        dispatch(getBookingRecordsReport(paylaod))
    }

    useEffect(() => {
        if (bookingRecord != null) {
            ExportToExcel(bookingRecord)
        }
    }, [bookingRecord])

    // const handleNextPage = () => {
    //     const locationUrl = "https://nanny-media.s3.eu-north-1.amazonaws.com/bill.html";
    //     navigation("/print", { state: { location: locationUrl } });
    // };

    return (
        <>
            <div className="dashboard">
                <div className='table_categories'>
                    <div className='categories_head'>
                        <div className='content_dropdown'>
                            <h2 style={{ marginBottom: 0 }}>Booking</h2>
                            <div>
                                <Button onClick={() => setOpenCalendar(true)}>Export to Excel</Button>
                            </div>
                        </div>
                        <div className='search_categories_btn'>
                            <div className="box">
                                <input required type="search" className="input" id="search"
                                    placeholder='Search...'
                                    autoComplete='off'
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                            <Button type='button' onClick={() => navigation('/new_booking')}>Add New</Button>
                        </div>
                    </div>

                    <Table responsive bordered>
                        <thead className='table_head_booking'>
                            <tr>
                                <th>Booking ID</th>
                                <th>Patient Name</th>
                                <th>Test/Package</th>
                                <th>Amount</th>
                                <th>Cash Amount</th>
                                <th>Online Amount</th>
                                <th>Due Amount</th>
                                <th>Date/Time</th>
                                <th>Payment Mode</th>
                                <th>Print</th>
                                {/* <th>Status</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredBookings.length > 0 ?
                                (
                                    <>
                                        {filteredBookings.map((item) => (
                                            <tr >
                                                <td onClick={() => navigation('/booking_detail', { state: item })} style={{ cursor: "pointer" }}>{item.bookingId}</td>
                                                <td>{item.userId.firstName + " " + item.userId.lastName}</td>
                                                <td>
                                                    {item.testIds?.length > 0
                                                        ? item.testIds
                                                            .filter((testItem) => testItem?.name)
                                                            .map((testItem) => testItem.name)
                                                            .join(", ")
                                                        : item.packageId?.name || "N/A"
                                                    }
                                                </td>
                                                <td>{item.amount}</td>
                                                <td>{item.cashAmount}</td>
                                                <td>{item.onlineAmount}</td>
                                                <td>{item.dueAmount}</td>
                                                <td>{item.bookingDate}/{item.bookingTime}</td>
                                                <td>{item.paymentMode == 1 ? "Cash" : "Online" ? item.paymentMode == 2 ? "Online" : "Cash/Online" : item.paymentMode == 3 ? "Cash/Online" : "Cash"}</td>
                                                <td>
                                                    <Button
                                                        onClick={() => onSubmitPrintClick(item)}
                                                    >
                                                        Print
                                                    </Button>
                                                </td>
                                                {/* {item.status == 2 ?
                                                    <td>
                                                        <Button className="btn_complete"
                                                            style={{ WebkitTextStroke: 'aliceblue', backgroundColor: item.status == 2 ? "green" : "", marginLeft: 0, border: 'transparent' }}
                                                        >
                                                            Completed
                                                        </Button>
                                                    </td> :
                                                    <td className='content_' style={{ WebkitTextStroke: 'aliceblue' }}>
                                                        <Button className="btn_complete"
                                                            onClick={() => onStatusChange(item)}
                                                            style={{ backgroundColor: item.status == 0 ? "red" : "#ffaa00", marginLeft: 0, border: 'transparent' }}
                                                        >
                                                            {item.status == 0 ? "Set In-Progress" : "Set Completed"}
                                                        </Button>
                                                    </td>
                                                } */}
                                            </tr>
                                        ))}
                                        < div className="bottom_pagination">
                                            <PaginationComponent
                                                skip={skip}
                                                setSkip={setSkip}
                                                count={count}
                                            />
                                        </div>
                                    </>
                                )
                                : (
                                    loaderVisible && <tr>
                                        <td colSpan="12" className="text-center">
                                            <ClipLoader color="#000" />
                                        </td>
                                    </tr>
                                )}
                        </tbody>
                    </Table>

                    <Modal
                        isOpen={isOpenCalender}
                        style={modal_setting}
                        onRequestClose={() => setOpenCalendar(false)}
                        className="calendar_width"
                    >
                        <div className='modal_content_center'>
                            <form>
                                <div className='flex_input_box'>
                                    <div className='head_input_flex'>
                                        <p>From <span>*</span></p>
                                        <input type='date' autoComplete='off' value={startDate} onChange={(e) => setStartDate(e.target.value)} /><br />
                                    </div>
                                </div>
                                <div className='flex_input_box'>
                                    <div className='head_input_flex'>
                                        <p>To <span>*</span></p>
                                        <input type='date' autoComplete='off' value={endDate} onChange={(e) => setEndDate(e.target.value)} /><br />
                                    </div>
                                </div>
                            </form>

                            <div className='submit_btn'>
                                <Button type='button' onClick={() => onSubmitDateClick()} style={{ letterSpacing: .4, fontWeight: '600' }}>Export</Button>
                            </div>
                        </div>
                    </Modal>

                </div >
            </div>
        </>
    );
};

export default Booking;