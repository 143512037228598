import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearUpdateBookingStatusData, updateBookingStatus } from '../../redux/updateBookingStatusSlice';
import { getBookingDetail } from '../../redux/getBookingDetailSlice';
import { clearUploadFileData, uploadFile } from '../../redux/uploadFileSlice';
import Modal from 'react-modal';

const modalDelete = {
    content: {
        position: 'absolute',
        top: '50%',
        left: "50%",
        right: "auto",
        marginRight: "-50%",
        transform: 'translate(-50%, -50%)',
        maxWidth: "100%",
        width: "420px",
        borderRadius: "8px",
        padding: '20px',
        textAlign: 'center',
    },
};

const BookingDetail = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const itemData = location.state
    const fileInputRef = useRef(null);

    const [item, setItem] = useState(null)
    const [status, setStatus] = useState(0)
    const [reports, setReports] = useState(null)
    const [reportArr, setReportArr] = useState([])
    const [disable, setDisable] = useState(false)
    const [isOpenSuccessUpdate, setOpenSuccessUpdate] = useState(false);


    const updateBookingStatusResponse = useSelector((state) => state.updateBookingStatusReducer.data);
    const getBookingDetailSuccess = useSelector((state) => state.getBookingDetailReducer.data);
    const uploadFileResponse = useSelector((state) => state.uploadFileReducer.data);

    const onStatusChange = () => {
        console.log("onStatusChange.status---", item.status)
        const status = item.status == 0 ? 1 : 2;
        setStatus(status);
        const payload = {
            status: status,
            bookingId: item._id
        }
        dispatch(updateBookingStatus(payload))
    }

    useEffect(() => {
        const paylaod = {
            bookingId: itemData._id
        }
        dispatch(getBookingDetail(paylaod))
    }, [])


    const submitReport = () => {
        if (reports != null) {
            dispatch(uploadFile(reports));
            if (fileInputRef.current) {
                fileInputRef.current.value = null; // Clear the file input after upload
            }
        }
        setDisable(false)
    }

    useEffect(() => {
        if (updateBookingStatusResponse != null && updateBookingStatusResponse.status === 1) {
            const paylaod = {
                bookingId: item._id
            }
            dispatch(getBookingDetail(paylaod))
        }
        return () => { dispatch(clearUpdateBookingStatusData()) };
    }, [updateBookingStatusResponse])

    useEffect(() => {
        console.log("getBookingDetailSuccess data===>", getBookingDetailSuccess)
        if (getBookingDetailSuccess != null && getBookingDetailSuccess.status === 1) {
            setItem(getBookingDetailSuccess.data)
            setReportArr(getBookingDetailSuccess.data.reportArr)
        }
    }, [getBookingDetailSuccess])

    const onSubmitDateClick = () => {
        onStatusChange()
    }

    const handleFileChange = (e) => {
        setReports(e.target.files[0]);
        setDisable(true)
    };

    useEffect(() => {
        if (uploadFileResponse != null && uploadFileResponse.Location != "") {
            const payload = {
                bookingId: itemData._id,
                reports: uploadFileResponse.Location,
            };
            dispatch(updateBookingStatus(payload));
            dispatch(clearUploadFileData())
        }
    }, [uploadFileResponse])

    const reportModal = () => {
        setTimeout(() => {
            setOpenSuccessUpdate(true);
        }, 300);
    }

    return (
        <>
            <div className="dashboard">
                <div className='table_categories'>
                    <div className='categories_head'>
                        <div className='content_dropdown'>
                            <h2>Booking Details</h2>
                        </div>
                    </div>

                    {item != null &&
                        <div className='booking_details'>
                            <div className='booking_details_flex'>
                                <div className='booking_details_content'>
                                    <p>Booking ID : </p>
                                    <span>{item.bookingId}</span>
                                </div>

                                <div className='booking_details_content'>
                                    <p>Patient Name : </p>
                                    <span>{item.userId.firstName + " " + item.userId.lastName}</span>
                                </div>
                            </div>

                            <div className='booking_details_flex'>
                                <div className='booking_details_content'>
                                    <p>Test/Package :
                                    </p>
                                    <span>
                                        {item.testIds.length > 0
                                            ? item.testIds.map((testItem) => testItem.name).join(", ")
                                            : item.packageId.name}
                                    </span>
                                </div>

                                <div className='booking_details_content'>
                                    <p>Commision Amount : </p>
                                    <span>{item.commisionAmount}</span>
                                </div>
                            </div>

                            <div className='booking_details_flex'>
                                <div className='booking_details_content'>
                                    <p>Commision Percentage : </p>
                                    <span>{item.commisionPercentage}</span>
                                </div>

                                <div className='booking_details_content'>
                                    <p>Amount : </p>
                                    <span>{item.amount}</span>
                                </div>
                            </div>

                            <div className='booking_details_flex'>
                                <div className='booking_details_content'>
                                    <p>Cash Amount : </p>
                                    <span>{item.cashAmount}</span>
                                </div>

                                <div className='booking_details_content'>
                                    <p>Online Amount : </p>
                                    <span>{item.onlineAmount}</span>
                                </div>
                            </div>

                            <div className='booking_details_flex'>
                                <div className='booking_details_content'>
                                    <p>Due Amount : </p>
                                    <span>{item.dueAmount}</span>
                                </div>

                                <div className='booking_details_content'>
                                    <p>Discount Amount : </p>
                                    <span>{item.discountAmount}</span>
                                </div>
                            </div>

                            <div className='booking_details_flex'>
                                <div className='booking_details_content'>
                                    <p>Payment By : </p>
                                    <span>{item.paymentMode == 1 ? "Cash" : item.paymentMode == 2 ? "Online" : "Cash/Online"}</span>
                                </div>

                                <div className='booking_details_content'>
                                    <p>Final Amount : </p>
                                    <span>{item.finalAmount}</span>
                                </div>
                            </div>

                            <div className='booking_details_flex '>
                                <div className='booking_details_content'>
                                    <p>Date/Time :
                                    </p>
                                    <span>{item.bookingDate}/{item.bookingTime}</span>
                                </div>

                                <div className='booking_details_content '>
                                    <p>Status :</p>
                                    <span>
                                        {item.status == 2 ?
                                            <span>
                                                <Button className="btn_complete"
                                                    style={{ WebkitTextStroke: 'aliceblue', backgroundColor: item.status == 2 ? "green" : "", marginLeft: 0, border: 'transparent' }}
                                                >
                                                    Completed
                                                </Button>
                                            </span> :
                                            <span className='content_' style={{ WebkitTextStroke: 'aliceblue' }}>
                                                <Button className="btn_complete"
                                                    onClick={() => onSubmitDateClick()}
                                                    style={{ backgroundColor: item.status == 0 ? "red" : "#ffaa00", marginLeft: 0, border: 'transparent' }}
                                                >
                                                    {item.status == 0 ? "Set In-Progress" : "Set Completed"}
                                                </Button>
                                            </span>
                                        }
                                    </span>
                                </div>
                            </div>

                            <div className='booking_details_flex booking_details_flex_left'>
                                <div className='booking_details_content booking_content_width'>
                                    <p>Reports : </p>
                                    <div className="add_picture_flex add_picture">

                                        <input type="file" className='file_upload' id='fileInput' ref={fileInputRef}
                                            onChange={handleFileChange}
                                        />
                                        {item !== null ? (
                                            item.reports.map((item, index) => (
                                                <div style={{ fontWeight: "600", marginTop: 25 }}>
                                                    Report  {index + 1}
                                                    <Link to={item} className='view_btn' target='_blank'>
                                                        View
                                                    </Link>
                                                </div>
                                            )))
                                            : ""}
                                    </div>
                                    <span> <Button style={{ WebkitTextStroke: 'aliceblue', border: 'transparent', opacity: disable != false ? 1 : .5, cursor: disable != false ? 'pointer' : "" }}
                                        className="btn_upload" disabled={!disable} onClick={() => { reportModal(); submitReport() }}>Upload</Button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                    <Modal
                        isOpen={isOpenSuccessUpdate}
                        style={modalDelete}
                        onRequestClose={() => setOpenSuccessUpdate(false)}
                        className="delete_modal"
                    >
                        <div className="modal_content_center">
                            <p>Upload Successfully</p>
                            <div className="button_group">
                                <Button
                                    className='ok_btn_success'
                                    onClick={() => setOpenSuccessUpdate(false)}
                                >
                                    OK
                                </Button>
                            </div>
                        </div>
                    </Modal>
                </div >
            </div >
        </>
    );
};

export default BookingDetail;