import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl, contactUsApi } from "../utils/constants";

export const contactUs = createAsyncThunk("contactUs", async (payload) => {
    try {
        const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                authorization: localStorage.getItem("token"),
            },
        };
        const search = payload.search

        if (search == "") {
            const url = `${ApiBaseUrl}${contactUsApi}?skip=0&limit=20`;
            console.log("URL ===>", url)
            const response = await axios.get(url, config);
            return response.data;
        } else {
            const url = `${ApiBaseUrl}${contactUsApi}?skip=0&limit=20&search=${search}`;
            console.log("URL ===>", url)
            const response = await axios.get(url, config);
            return response.data;
        }
    } catch (error) {
        throw error.response.data;
    }
});

const getContactUsSlice = createSlice({
    name: "contactUsReducer",

    initialState: {
        isLoading: false,
        data: null,
    },
    reducers: {
        clearContactUsData: (state) => {
            state.data = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(contactUs.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(contactUs.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(contactUs.rejected, (state) => {
                state.isError = false;
            });
    },
});

export const { clearContactUsData } = getContactUsSlice.actions;
export default getContactUsSlice.reducer;