//baseUrl
export const ApiBaseUrl = "https://api.kiorhealthcare.com/";

//API Name
export const loginApi = "v1/admin/login";
export const logoutApi = "v1/admin/logout";
export const getCategoryListApi = "v1/admin/category";
export const addCategoryApi = "v1/admin/category";
export const updateCategoryApi = "v1/admin/category";
export const getTestListApi = "v1/admin/tests";
export const addTestApi = "v1/admin/tests";
export const updateTestApi = "v1/admin/tests";
export const getPackageListApi = "v1/admin/package";
export const addPackageApi = "v1/admin/package";
export const updatePackageApi = "v1/admin/package";
export const getPackageDetailApi = "v1/user/getPackageDetails";
export const getPatientListApi = "/v1/admin/patients";
export const addPatientApi = "/v1/admin/patients";
export const updatePatientApi = "/v1/admin/patients";
export const getDoctorListApi = "/v1/admin/doctors";
export const addDoctorApi = "/v1/admin/doctors";
export const updateDoctorApi = "/v1/admin/doctors";
export const getHospitalListApi = "/v1/admin/hospitals";
export const addHospitalApi = "/v1/admin/hospitals";
export const updateHospitalApi = "/v1/admin/hospitals";
export const getBookingRecordApi = "/v1/admin/bookingRecords";
export const postBookingRecordApi = "/v1/admin/bookingRecords";
export const putBookingRecordApi = "/v1/admin/bookingRecords";
export const getCommisionApi = "v1/admin/commision";
export const updateCommisionApi = "v1/admin/commision";
export const getBookingRecordsReportApi = "/v1/admin/bookingRecordsReports";
export const getCommisionRecordsReportApi = "/v1/admin/commisionReports";
export const getBookingDetailApi = "/v1/admin/getBookingDetails";
export const feedbackApi = "/v1/admin/feedbacks";
export const contactUsApi = "/v1/admin/contactUs";
export const getBillReportApi = "/v1/admin/getBillReport";
export const couponsApi = "/v1/admin/coupons";

// http://43.205.184.78/v1/user/getPackageDetails?packageId=66c97a2da0e8bd0a2f3dd2f7