import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import PaginationComponent from '../Pagination/PaginationComponent';
import { ClipLoader } from 'react-spinners';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { couponsPostApi } from '../../utils/constants';
import Form from 'react-bootstrap/Form';
import Modal from 'react-modal';
import { couponPost } from '../../redux/CouponPostSlice';
import { coupon } from '../../redux/CouponSlice';
import { couponPut } from '../../redux/CouponPutSlice';


const modal_coupon = {
    content: {
        top: "20%",
        left: "50%",
        right: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, 0)",
        maxWidth: "100%",
        width: "420px",
        borderRadius: "8px",
    },
};

const modalDelete = {
    content: {
        position: 'absolute',
        top: '50%',
        left: "50%",
        right: "auto",
        marginRight: "-50%",
        transform: 'translate(-50%, -50%)',
        maxWidth: "100%",
        width: "420px",
        borderRadius: "8px",
        padding: '20px',
        textAlign: 'center',
    },
};

const Coupons = () => {

    const navigation = useNavigate();

    const [isOpen, setOpen] = useState(false);

    const dispatch = useDispatch();

    const [name, setName] = useState("");
    const [discountPercentage, setDiscountPercentage] = useState(0);
    const [minAmount, setMinAmount] = useState(0);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [from, setFrom] = useState(0);
    const [couponId, setCouponId] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isOpenDelete, setOpenDelete] = useState(false);
    const [isOpenSuccessUpdate, setOpenSuccessUpdate] = useState(false);
    const [isOpenSuccess, setOpenSuccess] = useState(false);
    const [search, setSearch] = useState("");
    const [skip, setSkip] = useState(0);
    const [count, setCount] = useState(1)
    const [coupons, setCoupons] = useState([])


    const couponPostSuccess = useSelector((state) => state.couponPostReducer.data);
    const couponPutSuccess = useSelector((state) => state.couponPutReducer.data);
    const couponSuccess = useSelector((state) => state.couponReducer.data);


    useEffect(() => {

        if (couponSuccess != null && couponSuccess.status == 1) {
            setLoaderVisible(false)
            setCoupons(couponSuccess.data);
            const count = Math.floor(couponSuccess.count / 20)
            setCount(count + 1)
        } else {
            setLoaderVisible(false)
        }
    }, [couponSuccess]);

    useEffect(() => {
        setLoaderVisible(true)
        const paylaod = {
            skip: skip,
            search: search,
        }
        dispatch(coupon(paylaod));
    }, [skip, search]);


    const onCouponSubmitClick = () => {
        if (name.length == 0) {
            alert("Please enter name!");
        } else if (discountPercentage.length == 0) {
            alert("Please enter discountPercentage");
        } else if (minAmount.length == 0) {
            alert("Please enter minAmount");
        }

        else {
            if (from == 0) {
                const payload = {
                    name: name,
                    discountPercentage: discountPercentage,
                    minAmount: minAmount,
                };
                setOpen(false);
                setTimeout(() => {
                    setOpenSuccess(true)
                }, 500);
                dispatch(couponPost(payload));
            }
            else {
                const payload = {
                    couponId: couponId,
                    name: name,
                    discountPercentage: discountPercentage,
                    minAmount: minAmount,
                };
                setOpen(false);
                setTimeout(() => {
                    setOpenSuccessUpdate(true)
                }, 500);
                dispatch(couponPut(payload))
            }
        }
    };

    const onDeleteClick = () => {
        if (selectedItem) {
            const payload = {
                name: selectedItem.name,
                discountPercentage: selectedItem.discountPercentage,
                minAmount: selectedItem.minAmount,
                couponId: selectedItem._id,
                isActive: 0,
                isDeleted: 1,
            }
            dispatch(couponPut(payload))
            setOpenDelete(false);
        }
        setSelectedItem(null);
    }

    const onEditClick = (item) => {
        setFrom(1)
        setName(item.name)
        setDiscountPercentage(item.discountPercentage)
        setMinAmount(item.minAmount)
        setOpen(true)
        setCouponId(item._id)
    }

    const onAddClick = () => {
        setFrom(0)
        setName("")
        setDiscountPercentage("")
        setMinAmount("")
        setOpen(true)
    }


    useEffect(() => {
        if (couponPutSuccess != null && couponPutSuccess.status == 1) {
            setName("")
            setDiscountPercentage("")
            setMinAmount("")
            const paylaod = {
                skip: 0,
                search: search,
            }
            dispatch(coupon(paylaod));
        }
    }, [couponPutSuccess])


    useEffect(() => {
        console.log("couponPostSuccess  ===>", couponPostSuccess)
        if (couponPostSuccess != null && couponPostSuccess.status == 1) {
            const paylaod = {
                skip: skip,
                search: search,
            }
            dispatch(coupon(paylaod));
        }
    }, [couponPostSuccess]);


    useEffect(() => {
        console.log('couponPostSuccess data ===>', couponPostSuccess)
        if (couponPostSuccess != null && couponPostSuccess.status == 1) {
            setOpen(false)
        } else if (couponPostSuccess != null) {
            alert(couponPostSuccess.message);
        }
    }, [couponPostSuccess]);


    const filteredCoupon = coupons.filter(item =>
        item.name.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <>
            <div className="dashboard">
                <div className='table_categories'>
                    <div className='categories_head'>
                        <h2>Coupon</h2>
                        <div className='search_categories_btn'>
                            <div className="box">
                                <input required type="search" className="input" id="search"
                                    autoComplete='off'
                                    placeholder='Search...'
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <Button onClick={() => onAddClick()}>Create</Button>
                            </div>
                        </div>
                    </div>

                    <div>
                        <Table responsive bordered >
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Discount Percentage</th>
                                    <th>Min Amount</th>
                                    <th>Status</th>
                                </tr>
                            </thead>


                            <tbody>
                                {filteredCoupon.length > 0 ?
                                    (
                                        <>
                                            {filteredCoupon.map((item) => (
                                                <tr>
                                                    <td style={{ padding: '15px 10px' }}>{item.name}</td>
                                                    <td style={{ padding: '15px 10px' }}>{item.discountPercentage}</td>
                                                    <td style={{ padding: '15px 10px' }}>{item.minAmount}</td>
                                                    <td>
                                                        <EditIcon onClick={() => onEditClick(item)} />
                                                        <DeleteForeverIcon
                                                            onClick={() => { setSelectedItem(item); setOpenDelete(true) }}
                                                            style={{ marginLeft: 20 }} />
                                                    </td>
                                                </tr>
                                            ))}
                                            < div className="bottom_pagination">
                                                <PaginationComponent
                                                    skip={skip}
                                                    setSkip={setSkip}
                                                    count={count}
                                                />
                                            </div>
                                        </>
                                    )
                                    : (
                                        loaderVisible && (
                                            <tr>
                                                <td colSpan="6" className="text-center">
                                                    <ClipLoader color="#000" />
                                                </td>
                                            </tr>
                                        )
                                    )}
                            </tbody>

                        </Table>
                    </div>

                    <Modal
                        isOpen={isOpen}
                        style={modal_coupon}
                        onRequestClose={() => setOpen(false)}
                        className="modal_content_coupon"
                    >
                        <div className='modal_content_center'>
                            <div className="modal_header">
                                <h2>Create Coupon</h2>
                                <button onClick={() => setOpen(false)} className="close_button">
                                    &times;
                                </button>
                            </div>
                            <Form>

                                <Form.Group>
                                    <Form.Label>Name</Form.Label><br />
                                    <Form.Control
                                        type="text"
                                        placeholder='name'
                                        required
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Discount Percentage</Form.Label><br />
                                    <Form.Control
                                        type="number"
                                        placeholder='discount percentage'
                                        required
                                        value={discountPercentage}
                                        onChange={(e) => {
                                            setDiscountPercentage(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Min Amount</Form.Label><br />
                                    <Form.Control
                                        type="number"
                                        placeholder='min amount'
                                        required
                                        value={minAmount}
                                        onChange={(e) => {
                                            setMinAmount(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                                <Button type='button' onClick={() => onCouponSubmitClick()} className='coupon_btn'>Submit</Button>
                            </Form>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={isOpenDelete}
                        style={modalDelete}
                        onRequestClose={() => setOpenDelete(false)}
                        className="delete_modal"
                    >
                        <div className="modal_content_center">
                            <p>Are you sure you want to delete</p>
                            <div className="button_group">
                                <Button
                                    className='cancel_btn'
                                    style={{ marginLeft: '10px' }}
                                    onClick={() => {
                                        setOpenDelete(false);
                                    }}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    className='ok_btn'
                                    onClick={() => onDeleteClick()}
                                >
                                    OK
                                </Button>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={isOpenSuccess}
                        style={modalDelete}
                        onRequestClose={() => setOpenSuccess(false)}
                        className="delete_modal"
                    >
                        <div className="modal_content_center">
                            <p>Add Coupon Successfully</p>
                            <div className="button_group">
                                <Button
                                    className='ok_btn_success'
                                    onClick={() => setOpenSuccess(false)}
                                >
                                    OK
                                </Button>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={isOpenSuccessUpdate}
                        style={modalDelete}
                        onRequestClose={() => setOpenSuccessUpdate(false)}
                        className="delete_modal"
                    >
                        <div className="modal_content_center">
                            <p>Update Successfully</p>
                            <div className="button_group">
                                <Button
                                    className='ok_btn_success'
                                    onClick={() => setOpenSuccessUpdate(false)}
                                >
                                    OK
                                </Button>
                            </div>
                        </div>
                    </Modal>

                </div >
            </div >
        </>
    );
};

export default Coupons;