import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl, couponsApi } from "../utils/constants";

export const coupon = createAsyncThunk("coupon", async (payload) => {
    try {
        const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                authorization: localStorage.getItem("token"),
            },
        };
        const skip = payload.skip
        const search = payload.search

        if (search == "") {
            if (skip != -1) {
                const url = `${ApiBaseUrl}${couponsApi}?skip=${skip}&limit=20`;
                return (await axios.get(url, config)).data;
            } else {
                const url = `${ApiBaseUrl}${couponsApi}`;
                return (await axios.get(url, config)).data;
            }
        } else {
            if (skip != -1) {
                const url = `${ApiBaseUrl}${couponsApi}?skip=${skip}&limit=20&search=${search}`;
                return (await axios.get(url, config)).data;
            } else {
                const url = `${ApiBaseUrl}${couponsApi}`;
                return (await axios.get(url, config)).data;
            }
        }
    } catch (error) {
        throw error.response.data;
    }


    //     const url = ApiBaseUrl + couponsApi;
    //     const response = await axios.get(url, payload, config);
    //     return response.data;
    // } catch (error) {
    //     throw error.response.data;
    // }
});

const CouponSlice = createSlice({
    name: "couponReducer",

    initialState: {
        isLoading: false,
        data: null,
    },
    reducers: {
        clearCouponData: (state) => {
            state.data = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(coupon.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(coupon.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(coupon.rejected, (state) => {
                state.isError = false;
            });
    },
});

export const { clearCouponData } = CouponSlice.actions;
export default CouponSlice.reducer;