import Pagination from "react-bootstrap/Pagination";

const PaginationComponent = ({
    skip,
    setSkip,
    count,
}) => (
    <>
        <div className="pagination_fixed ">
            <Pagination>
                <Pagination.Prev onClick={() => (skip > 0 ? setSkip(skip - 1) : setSkip(0))} />
                <Pagination.Item>{skip + 1}</Pagination.Item>
                <Pagination.Item className="slash">/</Pagination.Item>
                <Pagination.Item>{count === 0 ? 1 : count}</Pagination.Item>

                <Pagination.Next className="last_btn" onClick={() => skip < count - 1 ? setSkip(skip + 1) : ""} />
            </Pagination>
        </div>
    </>
);

export default PaginationComponent;