import React from 'react';
import { useLocation } from 'react-router-dom';


const Print = () => {

    const location = useLocation();
    const { location: locationUrl } = location.state || {};

    return (
        <>
            <div className="dashboard">
                <div className='table_categories'>
                    <div className='categories_head'>
                        <h2>Printed</h2>
                    </div>
                    {locationUrl ? (
                        <p>
                            The URL is: <a href={locationUrl} target="_blank" rel="noopener noreferrer">{locationUrl}</a>
                        </p>
                    ) : (
                        <p>No URL passed!</p>
                    )}

                </div >
            </div >
        </>
    );
};

export default Print;