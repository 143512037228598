import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./loginSlice";
import logoutReducer from "./logoutSlice";
import getCategoryListReducer from "./getCategoryListSlice";
import addCategoryReducer from "./addCategoriesSlice";
import updateCategoryReducer from "./updateCategorySlice";
import getTestListReducer from "./getTestListSlice";
import addTestReducer from "./addTestSlice";
import updateTestReducer from "./updateTestSlice";
import getPackageListReducer from "./getPackageListSlice";
import addPackageReducer from "./addPackageListSlice";
import updatePackageReducer from "./updatePackageSlice";
import uploadFileReducer from "./uploadFileSlice";
import getPackageDetailReducer from "./getPackageDetailSlice";
import getPatientListReducer from "./getPatientListSlice";
import addPatientReducer from "./addPatientSlice";
import updatePatientReducer from "./updatePatientSlice";
import getDoctorListReducer from "./getDoctorListSlice";
import addDoctorReducer from "./addDoctorSlice";
import updateDoctorReducer from "./updateDoctorSlice";
import getHospitalListReducer from "./getHospitalListSlice";
import addHospitalReducer from "./addHospitalSlice";
import updateHospitalReducer from "./updateHospitalSlice";
import getBookingRecordReducer from "./getBookingRecordSlice";
import postBookingRecordSReducer from "./postBookingRecordSlice";
import updateBookingStatusReducer from "./updateBookingStatusSlice";
import getCommisionReducer from "./getCommisionSlice";
import updateCommisionReducer from "./updateCommisionSlice";
import getBookingRecordsReportReducer from "./getBookingRecordsReportSlice";
import getCommisionRecordsReportReducer from "./getCommisionRecordsReportSlice";
import getBookingDetailReducer from "./getBookingDetailSlice";
import feedbackReducer from "./getFeedbackSlice";
import contactUsReducer from "./getContactUsSlice";
import getBillReportReducer from "./getBillReportSlice";
import couponPostReducer from "./CouponPostSlice";
import couponPutReducer from "./CouponPutSlice";
import couponReducer from "./CouponSlice";

const store = configureStore({
    reducer: {
        loginReducer: loginReducer,
        logoutReducer: logoutReducer,
        getCategoryListReducer: getCategoryListReducer,
        addCategoryReducer: addCategoryReducer,
        updateCategoryReducer: updateCategoryReducer,
        getTestListReducer: getTestListReducer,
        addTestReducer: addTestReducer,
        updateTestReducer: updateTestReducer,
        getPackageListReducer: getPackageListReducer,
        addPackageReducer: addPackageReducer,
        updatePackageReducer: updatePackageReducer,
        uploadFileReducer: uploadFileReducer,
        getPackageDetailReducer: getPackageDetailReducer,
        getPatientListReducer: getPatientListReducer,
        addPatientReducer: addPatientReducer,
        updatePatientReducer: updatePatientReducer,
        getDoctorListReducer: getDoctorListReducer,
        addDoctorReducer: addDoctorReducer,
        updateDoctorReducer: updateDoctorReducer,
        getHospitalListReducer: getHospitalListReducer,
        addHospitalReducer: addHospitalReducer,
        updateHospitalReducer: updateHospitalReducer,
        getBookingRecordReducer: getBookingRecordReducer,
        postBookingRecordSReducer: postBookingRecordSReducer,
        updateBookingStatusReducer: updateBookingStatusReducer,
        getCommisionReducer: getCommisionReducer,
        updateCommisionReducer: updateCommisionReducer,
        getBookingRecordsReportReducer: getBookingRecordsReportReducer,
        getCommisionRecordsReportReducer: getCommisionRecordsReportReducer,
        getBookingDetailReducer: getBookingDetailReducer,
        feedbackReducer: feedbackReducer,
        contactUsReducer: contactUsReducer,
        getBillReportReducer: getBillReportReducer,
        couponPostReducer: couponPostReducer,
        couponPutReducer: couponPutReducer,
        couponReducer: couponReducer,
    },
});

export default store;