import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import Modal from 'react-modal';
import Sidebar from '../Sidebar.js/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { getCategoryList } from '../../redux/getCategoryListSlice';
import { addCategory } from '../../redux/addCategoriesSlice';
import { updateCategory } from '../../redux/updateCategorySlice';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { uploadFile } from '../../redux/uploadFileSlice';
import PaginationComponent from '../Pagination/PaginationComponent';
import { ClipLoader } from "react-spinners";

const modal_setting = {
    content: {
        top: "20%",
        left: "50%",
        right: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, 0)",
        maxWidth: "100%",
        width: "420px",
        borderRadius: "8px",
    },
};

const modalDelete = {
    content: {
        position: 'absolute',
        top: '50%',
        left: "50%",
        right: "auto",
        marginRight: "-50%",
        transform: 'translate(-50%, -50%)',
        maxWidth: "100%",
        width: "420px",
        borderRadius: "8px",
        padding: '20px',
        textAlign: 'center',
    },
};

const Categories = () => {

    const dispatch = useDispatch();
    const [categories, setCategories] = useState([]);
    const [categoryId, setCategoryID] = useState("");
    const [skip, setSkip] = useState(0);
    const [name, setName] = useState("");
    const [isOpen, setOpen] = useState(false);
    const [from, setFrom] = useState(0);
    const [image, setImage] = useState(null)
    const [imageSrc, setImageSrc] = useState(null);
    const [imageLocation, setImageLocation] = useState("");
    const [count, setCount] = useState(1)
    const [search, setSearch] = useState("");
    const [loaderVisible, setLoaderVisible] = useState(false);

    const [isOpenSuccess, setOpenSuccess] = useState(false);
    const [isOpenSuccessUpdate, setOpenSuccessUpdate] = useState(false);
    const [isOpenDelete, setOpenDelete] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const getCategoryListSuccess = useSelector((state) => state.getCategoryListReducer.data);
    const addCategorySuccess = useSelector((state) => state.addCategoryReducer.data);
    const updateCategorySuccess = useSelector((state) => state.updateCategoryReducer.data);
    const uploadFileResponse = useSelector((state) => state.uploadFileReducer.data);


    useEffect(() => {
        if (getCategoryListSuccess != null && getCategoryListSuccess.status == 1) {
            setLoaderVisible(false);
            setCategories(getCategoryListSuccess.data);
            console.log("count ", getCategoryListSuccess.data.length)
            const count = Math.floor(getCategoryListSuccess.count / 20)
            setCount(count + 1)
        }
        else {
            setLoaderVisible(false);
        }
    }, [getCategoryListSuccess]);

    useEffect(() => {
        setLoaderVisible(true);
        const paylaod = {
            skip: skip,
            search: search,
        }
        dispatch(getCategoryList(paylaod));
    }, [skip, search]);


    useEffect(() => {
        if (addCategorySuccess != null && addCategorySuccess.status == 1) {
            const paylaod = {
                skip: skip,
                search: search,
            }
            dispatch(getCategoryList(paylaod));
        }
    }, [addCategorySuccess]);

    const onSubmitClick = () => {
        if (from == 0) {
            if (image != null) {
                if (name.length == 0) {
                    alert("Please enter name!");
                } else {
                    setOpen(false);
                    setTimeout(() => {
                        setOpenSuccess(true)
                    }, 500);
                    dispatch(uploadFile(image));
                }
            }
            else {
                alert("Please Select Image First")
            }
        }
        else {

            if (image != null) {
                if (name.length == 0) {
                    alert("Please enter name!");
                } else {
                    setOpen(false);
                    setTimeout(() => {
                        setOpenSuccessUpdate(true)
                    }, 500);
                    dispatch(uploadFile(image));
                }
            }
            else {
                const payload = {
                    categoryId: categoryId,
                    name: name,
                    image: imageLocation,
                };
                setOpen(false);
                setTimeout(() => {
                    setOpenSuccessUpdate(true)
                }, 500);
                dispatch(updateCategory(payload))
            }
        }
    };

    const toggleSwitch = (item) => {
        const payload = {
            name: item.name,
            categoryId: item._id,
            isActive: item.isActive == 1 ? 0 : 1,
            isDeleted: item.isDeleted
        }
        dispatch(updateCategory(payload))
    };

    useEffect(() => {
        if (updateCategorySuccess != null && updateCategorySuccess.status == 1) {
            setImageLocation("")
            setName("")
            const paylaod = {
                skip: 0,
                search: search,
            }
            dispatch(getCategoryList(paylaod));
        }
    }, [updateCategorySuccess]);

    const onDeleteClick = () => {
        if (selectedItem) {
            const payload = {
                name: selectedItem.name,
                categoryId: selectedItem._id,
                isActive: 0,
                isDeleted: 1
            }

            dispatch(updateCategory(payload))
        }
        setOpenDelete(false);
        setSelectedItem(null);
    }

    const onEditClick = (item) => {
        setImageSrc(null)
        setFrom(1)
        setCategoryID(item._id)
        setName(item.name)
        setImageLocation(item.image)
        setImage(null)
        setOpen(true)
    }

    const onAddClick = () => {
        setFrom(0)
        setName("");
        setImageSrc(null)
        setImage(null)
        setImageLocation("")
        setOpen(true);
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
                setImageLocation("")
            };
            reader.readAsDataURL(file);
            setImage(file)
        }
    };

    useEffect(() => {
        if (uploadFileResponse != null && uploadFileResponse.Location != "") {
            if (from == 0) {
                const payload = {
                    name: name,
                    image: uploadFileResponse.Location,
                };
                dispatch(addCategory(payload));
            }
            else {
                const payload = {
                    categoryId: categoryId,
                    name: name,
                    image: uploadFileResponse.Location,
                };
                dispatch(updateCategory(payload))
            }
        }
    }, [uploadFileResponse])


    const filteredCategories = categories.filter((category) =>
        category.name.toLowerCase().includes(search.toLowerCase())
    );

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    }

    return (
        <>
            <div className="dashboard">
                {/* <Sidebar /> */}
                <div className='table_categories'>
                    <div className='categories_head'>
                        <h2>Categories</h2>
                        <div className='search_categories_btn'>
                            <div className="box">
                                <input required type="search" className="input" id="search"
                                    autoComplete='off'
                                    placeholder='Search...'
                                    value={search}
                                    onChange={handleSearchChange}
                                />
                            </div>
                            <Button type='button' onClick={() => onAddClick()}>Add Categories</Button>
                        </div>
                    </div>

                    <div>
                        <Table responsive bordered >
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Image</th>
                                    <th>Status</th>
                                    <th>Option</th>
                                </tr>
                            </thead>


                            <tbody>

                                {filteredCategories.length > 0 ?
                                    (
                                        <>
                                            {filteredCategories.map((item) => (
                                                <tr>
                                                    <td>{item.name}</td>
                                                    <td>{item.image != "image" && <img src={item.image} alt="Uploaded" style={{ maxWidth: '100%' }} />}</td>
                                                    <td>
                                                        <div className='switch_btn_center'>
                                                            <div className={`switch ${item.isActive ? 'on' : 'off'}`} onClick={(val) => toggleSwitch(item)}>
                                                                <div className="toggle"></div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <EditIcon onClick={() => onEditClick(item)} />
                                                        <DeleteForeverIcon onClick={() => { setSelectedItem(item); setOpenDelete(true) }} style={{ marginLeft: 20 }} />
                                                    </td>
                                                </tr>
                                            ))}
                                            < div className="bottom_pagination">
                                                <PaginationComponent
                                                    skip={skip}
                                                    setSkip={setSkip}
                                                    count={count}
                                                />
                                            </div>
                                        </>
                                    )
                                    : (
                                        loaderVisible && (
                                            <tr>
                                                <td colSpan="6" className="text-center">
                                                    <ClipLoader color="#000" />
                                                </td>
                                            </tr>
                                        )
                                    )}
                            </tbody>

                        </Table>
                    </div>

                    <Modal
                        isOpen={isOpen}
                        style={modal_setting}
                        onRequestClose={() => setOpen(false)}
                    >
                        <div className='modal_content_center'>
                            <div className="modal_header">
                                <h2>Add Category</h2>
                                <button onClick={() => setOpen(false)} className="close_button">
                                    &times;
                                </button>
                            </div>
                            <p>Image <span style={{ color: '#10519e' }}>*</span></p>
                            <div className="add_picture">
                                <input type="file" onChange={handleFileChange} className='file_upload' id='fileInput' />
                                <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
                                    {(imageSrc || imageLocation != "") ? <img src={imageSrc != null ? imageSrc : imageLocation} alt="Uploaded" style={{ maxWidth: '100%' }} /> : <AddIcon />}
                                </label>
                                <AddIcon />
                            </div>
                            <p >Name <span style={{ color: '#10519e' }}>*</span></p>
                            <input type='text' placeholder='name' autoComplete='off' value={name} onChange={(e) => setName(e.target.value)} /><br />
                            <div className='submit_btn'>
                                <Button onClick={() => onSubmitClick()} > Submit</Button>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={isOpenDelete}
                        style={modalDelete}
                        onRequestClose={() => setOpenDelete(false)}
                        className="delete_modal"
                    >
                        <div className="modal_content_center">
                            <p>Are you sure you want to delete</p>
                            <div className="button_group">
                                <Button
                                    className='cancel_btn'
                                    style={{ marginLeft: '10px' }}
                                    onClick={() => {
                                        setOpenDelete(false);
                                    }}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    className='ok_btn'
                                    onClick={() => onDeleteClick()}
                                >
                                    OK
                                </Button>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={isOpenSuccess}
                        style={modalDelete}
                        onRequestClose={() => setOpenSuccess(false)}
                        className="delete_modal"
                    >
                        <div className="modal_content_center">
                            <p>Add Category Successfully</p>
                            <div className="button_group">
                                <Button
                                    className='ok_btn_success'
                                    onClick={() => setOpenSuccess(false)}
                                >
                                    OK
                                </Button>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={isOpenSuccessUpdate}
                        style={modalDelete}
                        onRequestClose={() => setOpenSuccessUpdate(false)}
                        className="delete_modal"
                    >
                        <div className="modal_content_center">
                            <p>Update Successfully</p>
                            <div className="button_group">
                                <Button
                                    className='ok_btn_success'
                                    onClick={() => setOpenSuccessUpdate(false)}
                                >
                                    OK
                                </Button>
                            </div>
                        </div>
                    </Modal>
                </div >
            </div >
        </>
    );
};

export default Categories;